<template>
  <div class="more_rank">
    <Header :id='1'></Header>
    <div class="ranking">
      <div class="ranking_box">
        <div class="rank_tit">
          <span class="rank_tit_kuai"></span>
          <span class="rank_tit_text">TOP100 {{$t('lang.ranking')}}</span>
        </div>
        <div class="rank_table">
          <el-table
          v-loading="loading"
          :data="tableData"
          style="width: 100%;"
          >
            <el-table-column
              prop="name"
              :label="$t('lang.币种')"
              width="250">
              <template slot-scope="scope">
                <img :src="(scope.row.name!='USDD'&&scope.row.name!='LDO'&&scope.row.name!='LUNC')?require('@/assets/images/icon/'+scope.row.name.toLowerCase()+'_icon.png'):require('@/assets/images/icon/wen_icon.png')" alt="" width="22" :style="{marginRight: '8px'}">
                <span>{{ scope.row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="market"
              :label="$t('lang.市场价')+'（USDT）'"
              width="250">
            </el-table-column>
            <el-table-column
              prop="limit"
              :label="$t('lang.24H额')+'（￥）'"
              width="250">
            </el-table-column>
            <el-table-column
              prop="num"
              :label="$t('lang.流通数量')"
              width="250">
            </el-table-column>
            <el-table-column
              prop="zf"
              :label="$t('lang.24H涨幅')"
              width="150">
              <template slot-scope="scope">
                <span :style="{color: scope.row.zf.slice(0,1)=='-'?'#ED3756':'#05AD83'}">{{ scope.row.zf }}%</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>

</template>
<script>
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import { ranklist } from '../../interface.js'
export default {
  components: {
    Header,
    Footer
  },
  name: 'MoreRank',
  data() {
    return {
      tableData: [],
      loading: true
    }
  },
  methods: {
    ranklist () {
      ranklist(res=>{
        // console.log(res.data)
        if(res.code==200){
          this.loading = false;
          var data  = res.data;
          var list = []
          for(var i in data){
            list.push({
              name: i,
              market: data[i].price,
              limit: data[i].limit,
              num: data[i].num,
              zf: data[i].zf,
            });
          }
          list = list.sort(function(x, y){
              return y.zf - x.zf;
          });
          this.tableData = list;
        }
      })
    }
  },
  mounted () {
    this.ranklist ();
  },
  created () {
    if(localStorage.token==""||localStorage.token==null||localStorage.token==undefined){     
        this.$router.push('/');
    }
  }
}
</script>
<style lang="less">
@import '../../assets/styles/varibles.less';
.ranking{
  width: 100%;
  min-height: 600px;
  // overflow: hidden;
  background: #fff;
  padding: 70px 0;
  .ranking_box{
    width: 100%;
    color: @grey[4];
    margin: 0 auto;
    max-width: @maxWidth;
    min-width: @minWidth;
    .rank_tit{
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .rank_tit_kuai{
        width: 30px;
        height: 30px;
        display: flex;
        background: @bgColor1;
        margin-right: 20px;
      }
      .rank_tit_text{
        color: @bgColor1;
        display: flex;
        font-size: 26px;
      }
    }
    .el-table__row{
      td:last-child{
        text-align: right;
      }
      td:first-child{
        text-align: left;
      }
    }
    .has-gutter{
      th:nth-child(5){
        .cell{
          text-align: right;
        }
      }
    }
    .look {
      color: @grey[5];
      height: 32px;
      margin: 20px 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .look:hover{
      color: @fontColor;
    }
    .rank_table{
      border: 1px solid @grey[6];
      padding: 0 24px 16px;
    }
  }
}
</style>